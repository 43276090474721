<ng-container *ngIf="translations$ | async"></ng-container>

<div
  class="profit-header"
  [ngClass]="{
    'profit-header--light': theme === 'light',
    'profit-header--dark': theme === 'dark'
  }"
>
  <div class="profit-header__container">
    <div class="profit-header__container__top-bar">
      <div class="profit-header__container__top-bar__left">
        <!-- <div class="profit-header__container__top-bar__language">
          <p-dropdown [options]="languages" optionLabel="name"></p-dropdown>
        </div> -->
      </div>
      <div class="profit-header__container__top-bar__right">
        @if (isBlogEnabled) {
          <a
            [routerLink]="'blog'"
            class="hidden lg:inline"
            [ngClass]="{
              'text-white': theme === 'dark',
              'text-900': theme === 'light'
            }"
            >{{ 'ui.blog' | transloco }}</a
          >
        }
        <div class="flex align-items-end">
          <span
            style="font-size: 0.8rem"
            class="pi pi-envelope ml-2 mr-1"
          ></span>
          <span>{{ contactEmail }}</span>
        </div>
        @if (contactPhone) {
          <div class="flex align-items-end">
            <span
              style="font-size: 0.8rem"
              class="pi pi-phone ml-2 mr-1"
            ></span>
            <a
              [href]="'tel:' + contactPhone"
              class="no-underline"
              [ngClass]="{
                'text-white': theme === 'dark',
                'text-900': theme === 'light'
              }"
              >{{ contactPhone }}</a
            >
          </div>
        }
      </div>
    </div>
    <div class="profit-header__container__navigation">
      <p-menubar [model]="items" id="menubar" class="w-full">
        <ng-template pTemplate="start">
          <a [routerLink]="['/']">
            @defer {
              <img
                [src]="
                  theme === 'light'
                    ? '/assets/ui/profitmark-logo-dark.svg'
                    : '/assets/ui/profitmark-logo-white.svg'
                "
                alt="Profitmark"
                width="189"
                height="58"
              />
            }
          </a>
        </ng-template>
      </p-menubar>
      <div
        class="profit-header__container__navigation__actions hidden lg:flex"
        *ngIf="(isLoggedIn$ | async) === false; else dashboardLink"
      >
        <p-button
          [label]="'ui.sign-in' | transloco"
          [icon]="'pi pi-sign-in'"
          [severity]="'primary'"
          (onClick)="goToSignIn()"
        ></p-button>
      </div>
      <ng-template #dashboardLink>
        <div
          class="profit-header__container__navigation__actions hidden lg:flex"
        >
          <p-button
            styleClass="p-button-primary"
            [label]="'ui.dashboard' | transloco"
            (onClick)="goToDashboard()"
          ></p-button>
          <p-button
            [styleClass]="
              'p-button-help' + (theme === 'dark' ? ' p-button-text' : '')
            "
            icon="pi pi-sign-out"
            (onClick)="signOut()"
          ></p-button>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #menuButtons> </ng-template>
